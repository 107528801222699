<template>
  <div>
    <location />
    <div class="page wrap-1200 course-info">
      <!-- 
      <h3 class="subtitle">
        {{ $t("page.golfcourse.golfcourse_information") }}
      </h3>
       -->
      <p class="course-name border-top-dgray border-bottom-gray fs-lg-18 lh-sm">
        {{ basicinfo.coursename }}
      </p>

      <div class="row g-0 border-bottom-gray">
        <div class="col-md-6 d-flex position-relative">
          <img :src="(basicinfo.path + '/' + basicinfo.image) | get_img" />
          <div class="course-state">
            <div class="prog-tournament bg-black" v-if="tournamentlist.length">
              T
            </div>
            <div class="prog-event bg-pink" v-if="awardrulelist.length">E</div>
          </div>
        </div>

        <div class="col-md-6 d-flex flex-column border-top border-md-0">
          <div class="row g-0 border-bottom h-100">
            <div class="col bg-gray d-flex justify-content-center">
              <label class="d-table-cell align-self-center">
                {{ $t("title.golfcourse.golfcourse_difficulty") }}
              </label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                <i
                  class="material-icons-outlined"
                  :class="
                    basicinfo.field >= index ? 'text-yellow' : 'text-lightgray'
                  "
                  v-for="index in 5"
                  :key="index"
                >
                  star
                </i>
              </p>
            </div>
          </div>
          <div class="row g-0 border-bottom h-100">
            <div class="col bg-gray d-flex justify-content-center">
              <label class="d-table-cell align-self-center">
                {{ $t("title.golfcourse.green_difficulty") }}
              </label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                <i
                  class="material-icons"
                  :class="
                    basicinfo.green >= index ? 'text-green' : 'text-lightgray'
                  "
                  v-for="index in 5"
                  :key="index"
                >
                  flag
                </i>
              </p>
            </div>
          </div>
          <div class="row g-0 border-bottom h-100">
            <div class="col bg-gray d-flex justify-content-center">
              <label class="d-table-cell align-self-center"
                >{{ $t("title.common.hole") }} /
                {{ $t("title.common.par") }}</label
              >
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{ basicinfo.holesum + "H" + " / " + "PAR" + basicinfo.parsum }}
              </p>
            </div>
          </div>
          <div class="row g-0 h-100">
            <div class="col bg-gray d-flex justify-content-center">
              <label class="d-table-cell align-self-center">{{
                $t("title.common.distance")
              }}</label>
            </div>
            <div class="col d-flex">
              <p class="d-table-cell align-self-center">
                {{ basicinfo.distance | comma }}m
              </p>
            </div>
          </div>
        </div>
      </div>

      <table class="table board mt-5" v-if="tournamentlist.length">
        <thead>
          <tr>
            <th class="col-auto d-lg-table-cell">
              {{ $t("title.common.tournament") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.type") }}
            </th>
            <th class="col-auto d-none d-sm-table-cell">
              {{ $t("title.common.date") }}
            </th>
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.course") }}
            </th>
            <!-- <th class="col-auto d-lg-table-cell text-center">{{ $t("title.common.location") }}</th> -->
            <!-- <th class="col-auto d-none d-lg-table-cell">Point</th> -->
            <th class="col-auto d-none d-lg-table-cell">
              {{ $t("title.common.status") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody v-if="tournamentlist.length">
          <template v-for="(item, index) in tournamentlist">
            <tr :key="index">
              <td class="col-auto d-lg-table-cell">
                <router-link
                  :to="{
                    name: 'Condition',
                    params: { id: item.tournamentinfo.tournamentno },
                    query: {
                      searchtype: searchData.searchtype,
                      searchname: searchData.searchname,
                    },
                  }"
                  class="more"
                >
                  {{ item.tournamentinfo.title }}
                </router-link>
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                {{ item.tournamentinfo.type == 1 ? "Public" : "Private" }}
              </td>
              <td class="col-auto d-none d-sm-table-cell text-date">
                {{
                  $dayjs(item.tournamentinfo.startdate).format("YYYY-MM-DD") +
                  "~" +
                  $dayjs(item.tournamentinfo.enddate).format("YYYY-MM-DD")
                }}
              </td>
              <td class="col-auto d-none d-lg-table-cell">
                <template v-for="(coures, c_index) in item.tournamentcourse">
                  <p :key="c_index">{{ coures.coursename }}</p>
                </template>
              </td>
              <!-- <td class="col-auto d-lg-table-cell">
              <template v-for="(shop, s_index) in item.tournamentshop">
                <p :key="s_index">{{ shop.shopname }}</p>
              </template>
            </td> -->
              <!-- <td class="col-auto d-none d-lg-table-cell text-cyan">
              {{ item.tournamentinfo.point | comma }}p
            </td> -->
              <td class="col-auto d-none d-lg-table-cell">
                {{ tournament_status(item.tournamentinfo.status) }}
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="d-lg-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="5">
                <div class="toggle-content">
                  <!-- <div class="row g-0">
                  <div class="col-6">No.</div>
                  <div class="col-6">{{ item.tournamentinfo.no }}</div>
                </div> -->
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.type") }}</div>
                    <div class="col">
                      {{ item.tournamentinfo.type == 1 ? "Public" : "Private" }}
                    </div>
                  </div>
                  <div class="row d-sm-none">
                    <div class="col-4">{{ $t("title.common.date") }}</div>
                    <div class="col text-date">
                      {{
                        $dayjs(item.tournamentinfo.startdate).format(
                          "YYYY-MM-DD"
                        ) +
                        "~" +
                        $dayjs(item.tournamentinfo.enddate).format("YYYY-MM-DD")
                      }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.common.course") }}
                    </div>
                    <div class="col">
                      <template
                        v-for="(coures, c_index) in item.tournamentcourse"
                      >
                        <p :key="c_index">{{ coures.coursename }}</p>
                      </template>
                    </div>
                  </div>
                  <!-- <div class="row g-0">
                  <div class="col-6">Point</div>
                  <div class="col-6 text-cyan">
                    {{ item.tournamentinfo.point | comma }}p
                  </div>
                </div> -->
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.status") }}</div>
                    <div class="col">
                      {{ tournament_status(item.tournamentinfo.status) }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="5">{{ $t("text.common.no_data") }}</td>
          </tr>
        </tbody>
      </table>

      <p class="mt-3 text-subscript fs-14 text-orange">
        <span class="material-icons-outlined me-1">info</span
        >{{ $t("text.golfcourse.title_information") }}
      </p>

      <!-- Sub Course -->
      <div
        v-for="(anc, index) in subcourse"
        :key="index"
        class="course-details"
      >
        <p class="info">
          {{ $t("title.common.subcourse") }} {{ anc.subcourseseq }} [{{
            anc.title
          }}]
        </p>
        <div class="position-relative">
          <table class="table scorecard_title">
            <thead>
              <tr class="bg-gray">
                <th>{{ $t("title.common.hole") }}</th>
              </tr>
              <tr class="bg-gray">
                <th>{{ $t("title.common.par") }}</th>
              </tr>
              <tr class="bg-gray">
                <th class="border-bottom-0">
                  {{ $t("title.common.handicap") }}
                </th>
              </tr>
            </thead>
          </table>
          <div class="courselist view list" v-if="anc.subcoursehole.length > 0">
            <div class="in overflow-auto fix-table">
              <table class="table">
                <tbody>
                  <tr>
                    <td
                      v-for="(inc, index) in anc.subcoursehole"
                      :key="index"
                      @click="showModal(inc.hole_no, anc.subcourseseq)"
                      class="position-relative"
                    >
                      <span class="rounded-circle info-hole"
                        >{{ inc.hole_no }}
                      </span>
                      <span
                        class="position-absolute top-0 start-0 badge bg-pink"
                        v-if="
                          awardrulelist.find((v) => v.hole_fk == inc.hole_pk)
                        "
                      >
                        E
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-for="(inc, index) in anc.subcoursehole"
                      :key="index"
                      @click="showModal(inc.hole_no, anc.subcourseseq)"
                    >
                      <span class="rounded-circle info-par">{{
                        inc.par_score
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-for="(inc, index) in anc.subcoursehole"
                      :key="index"
                    >
                      {{ inc.handicap }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="btn-bottom-wrapper">
        <b-button
          size="xl"
          variant="outline-primary"
          :aria-label="$t('button.common.list')"
          @click="
            $router.push({
              name: 'CourseList',
              query: {
                countryno: $route.query.countryno,
              },
            })
          "
        >
          {{ $t("button.common.list") }}
        </b-button>
      </div>
    </div>

    <b-modal
      id="modal-hole-info"
      ref="modal-hole-info"
      size="xl"
      centered
      scrollable
      hide-footer
    >
      <template #modal-header>
        <h3>
          <p>
            {{ $t("title.common.subcourse") }}
            {{ hole_model.coursubtype }} [{{ hole_model.description }}]
          </p>
          <!-- ({{ hole_model.subcoursename }}) -->
          <div class="hole-details mt-2">
            <span>{{ $t("title.common.hole") }} {{ hole_model.hole }}</span>
            <span>{{ $t("title.common.par") }} {{ hole_model.par }}</span>
            <span
              >{{ $t("title.common.handicap") }} {{ hole_model.handicap }}</span
            >
          </div>
        </h3>
        <b-button
          variant="close"
          :aria-label="$t('button.common.close')"
          @click="$bvModal.hide('modal-hole-info')"
        >
        </b-button>
      </template>

      <table class="table board toggle-xl mb-5" v-if="items.length">
        <thead>
          <tr>
            <th class="d-lg-table-cell">
              {{ $t("title.common.title") }}
            </th>
            <th class="d-none d-lg-table-cell">
              {{ $t("title.common.type") }}
            </th>
            <th class="d-none d-lg-table-cell">
              {{ $t("title.common.date") }}
            </th>
            <!-- <th class="d-none d-lg-table-cell">
              {{ $t("title.common.course") }}
            </th> -->
            <th class="d-none d-xl-table-cell w-1">
              {{ $t("title.common.prizes") }}
            </th>
            <th class="d-none d-xl-table-cell w-1">
              {{ $t("title.common.entry_limit") }}
            </th>
            <th class="d-none d-xl-table-cell w-1">
              {{ $t("title.common.status") }}
            </th>
            <th class="d-none d-lg-table-cell w-1">
              {{ $t("title.common.leaderboard") }}
            </th>
            <th class="th-arrow">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in items">
            <tr :key="index">
              <!-- <td class="d-none d-lg-table-cell">{{ item.no }}</td> -->
              <td class="d-lg-table-cell text-black">
                {{ item.title }}
              </td>
              <td class="d-none d-lg-table-cell">
                {{ event_type(item.type) }}
              </td>
              <td class="d-none d-lg-table-cell text-date">
                {{
                  $dayjs(item.startdate).format("YYYY-MM-DD") +
                  "~" +
                  $dayjs(item.enddate).format("YYYY-MM-DD")
                }}
              </td>
              <!-- <td class="d-sm-table-cell">
                {{ item.coursename }}
              </td> -->
              <td class="d-none d-xl-table-cell w-1 text-point">
                {{ item.point }}
              </td>
              <td class="d-none d-xl-table-cell w-1 text-red">
                {{ item.entrylimit | comma }}
              </td>
              <td class="d-none d-xl-table-cell w-1">
                {{ event_status(item.status_cd) }}
              </td>
              <td class="d-none d-lg-table-cell w-1">
                <button
                  type="button"
                  class="btn btn-outline-primary btn-xs"
                  v-if="item.type > 10"
                  @click="
                    $router.push({
                      name: 'MiniGameRanking',
                      params: { eventno: item.eventno },
                    })
                  "
                >
                  {{ $t("button.common.view") }}
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary btn-xs"
                  v-else
                  @click="
                    $router.push({
                      name: 'WinAMiniGameList',
                      params: { eventno: item.eventno },
                    })
                  "
                >
                  {{ $t("button.common.view") }}
                </button>
              </td>
              <td class="td-arrow" @click="toggle_tr(index)">
                <img
                  src="/img/arrow_none_collapsed.png"
                  :class="{ 'btn-toggle-arrow': view_hide === index }"
                />
              </td>
            </tr>
            <tr
              class="d-xl-none"
              :class="{ show: view_hide === index }"
              :key="index + 'r'"
              v-if="view_hide === index"
            >
              <td colspan="7">
                <div class="toggle-content">
                  <div class="row d-lg-none">
                    <div class="col-4">{{ $t("title.common.type") }}</div>
                    <div class="col-8">{{ event_type(item.type) }}</div>
                  </div>
                  <div class="row d-lg-none">
                    <div class="col-4">{{ $t("title.common.date") }}</div>
                    <div class="col-8 text-date">
                      {{
                        $dayjs(item.startdate).format("YYYY-MM-DD") +
                        "~" +
                        $dayjs(item.enddate).format("YYYY-MM-DD")
                      }}
                    </div>
                  </div>
                  <!-- <div class="row g-0">
                    <div class="col-4">{{ $t("title.common.course") }}</div>
                    <div class="col-8">
                      {{ item.coursename }}
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.prizes") }}</div>
                    <div class="col-8 text-point">{{ item.point }}</div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      {{ $t("title.common.entry_limit") }}
                    </div>
                    <div class="col-8 text-red">
                      {{ item.entrylimit | comma }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">{{ $t("title.common.status") }}</div>
                    <div class="col-8">
                      {{ event_status(item.status_cd) }}
                    </div>
                  </div>
                  <div class="row d-lg-none">
                    <div class="col-4">
                      {{ $t("title.common.leaderboard") }}
                    </div>
                    <div class="col-8">
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-xs"
                        v-if="item.type > 10"
                        @click="
                          $router.push({
                            name: 'MiniGameRanking',
                            params: { eventno: item.eventno },
                          })
                        "
                      >
                        {{ $t("button.common.view") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-xs"
                        v-else
                        @click="
                          $router.push({
                            name: 'WinAMiniGameList',
                            params: { eventno: item.eventno },
                          })
                        "
                      >
                        {{ $t("button.common.view") }}
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <div class="minimap row g-4">
        <div class="col">
          <div class="card h-100">
            <h6 class="p-3 fs-6 fs-lg-18 border-bottom">
              {{ $t("title.golfcourse.golfcourse_minimap") }}
            </h6>
            <div class="card-body">
              <img
                :src="
                  (hole_model.fieldpath + '/' + hole_model.fieldmap) | get_img
                "
              />
            </div>
          </div>
        </div>

        <template v-if="hole_model.greenmapl != null">
          <div class="col">
            <div class="card h-100">
              <h6 class="p-3 fs-6 fs-lg-18 border-bottom">
                {{ $t("title.golfcourse.green_minimap_left") }}
              </h6>
              <div class="card-body">
                <img
                  :src="
                    (hole_model.greenpathl + '/' + hole_model.greenmapl)
                      | get_img
                  "
                  v-if="hole_model.greenmapl != null"
                />
              </div>
            </div>
          </div>
        </template>

        <template v-if="hole_model.greenmapr != null">
          <div class="col">
            <div class="card h-100">
              <h6 class="p-3 fs-6 fs-lg-18 border-bottom">
                {{ $t("title.golfcourse.green_minimap_right") }}
              </h6>
              <div class="card-body">
                <img
                  :src="
                    (hole_model.greenpathr + '/' + hole_model.greenmapr)
                      | get_img
                  "
                  v-if="hole_model.greenmapr != null"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </b-modal>
  </div>
</template>

<script>
import location from "@/components/Location";
import ax from "@/api/golfcourse";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "CourseView",
  components: {
    location,
  },
  data() {
    return {
      searchData: {
        courseno: this.$route.params.id || "",
      },
      basicinfo: [
        {
          area: "",
          coursename: "",
          path: "",
          image: "",
          field: "",
          green: "",
          hole: "",
          par: "",
          distance: "",
        },
      ],
      // tourdefine: { list: [], tournamentlist: [] },
      tournamentlist: [],
      awardrulelist: [],
      subcourse: [
        {
          subcourseseq: "",
          subcourseno: "",
          subcoursename: "",
          subcoursehole: [],
        },
      ],
      hole_model: {
        hole: "",
        coursubtype: "",
        subcoursename: "",
        par: "",
        fieldpath: "",
        fieldmap: "",
        greenpathl: "",
        greenmapl: "",
        greenpathr: "",
        greenmapr: "",
        description: "",
      },
      subcourselist: [],
      subcourselistcnt: 0,
      scroll: "r",
      hold: false,
      inter_clear: Object,
      items: [],
      view_hide: null,
      view_hide2: null,
    };
  },
  methods: {
    get_courseview() {
      ax.get_courseview(this.searchData, (flag, data) => {
        console.log(flag, data);
        if (flag) {
          this.basicinfo = data.basicinfo;
          this.tournamentlist = data.tournamentlist;
          this.awardrulelist = data.awardrulelist;

          this.subcourse = data.subcourse || [];
        } else {
          alert(data);
        }
      });
    },
    showModal(hole, type) {
      // console.log(hole,type);
      ax.get_courseholeview(
        this.searchData.courseno,
        hole,
        type,
        (flag, data) => {
          if (flag) {
            console.log(flag, data);
            this.hole_model.hole = hole;
            this.hole_model.coursubtype = type;
            this.hole_model.subcoursename = data.courseholeview.subcoursename;
            this.hole_model.par = data.courseholeview.par;
            this.hole_model.fieldpath = data.courseholeview.fieldpath;
            this.hole_model.fieldmap = data.courseholeview.fieldmap;
            this.hole_model.greenpathl = data.courseholeview.greenpathl;
            this.hole_model.greenmapl = data.courseholeview.greenmapl;
            this.hole_model.greenpathr = data.courseholeview.greenpathr;
            this.hole_model.greenmapr = data.courseholeview.greenmapr;
            this.hole_model.description = data.courseholeview.description;
            this.hole_model.handicap = data.courseholeview.handicap;
            this.items = data.eventlist;
            this.$refs["modal-hole-info"].modalOuterStyle.zIndex = 9999;
            this.$refs["modal-hole-info"].show();
          } else {
            alert(data);
          }
        }
      );
    },
    hideModal() {
      this.$refs["modal-hole-info"].hide();
    },
    cc_img() {
      if (this.basicinfo.image !== null)
        return this.basicinfo.path + "/" + this.basicinfo.image;
      else return "/img/noccimage.jpg";
    },
    scroll_x() {
      let content = document.querySelector(".list");
      let scrollAmount = 0;
      if (this.scroll === "r") {
        //content.scrollLeft += 40
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft += 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      } else {
        //content.scrollLeft -= 40
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft -= 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      }
    },
    course_click(direction) {
      let scrollAmount = 0;
      let content = document.querySelector(".list");
      if (direction === "r") {
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft += 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      } else {
        scrollAmount = 0;
        let slideTimer = setInterval(function () {
          content.scrollLeft -= 40;
          scrollAmount += 40;
          if (scrollAmount >= 100) {
            window.clearInterval(slideTimer);
          }
        }, 25);
      }
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    toggle_tr2(index) {
      if (this.view_hide2 === index) this.view_hide2 = null;
      else this.view_hide2 = index;
    },
  },
  created() {
    this.get_courseview();
  },
  watch: {
    hold: function () {
      if (this.hold) {
        this.inter_clear = setInterval(this.scroll_x, 1000);
      } else {
        clearInterval(this.inter_clear);
      }
    },
    "searchData.searchname": function () {
      this.searchData.pageno = 1;
      this.get_eventwinninglist();
    },
    "searchData.searchtype": function () {
      this.searchData.pageno = 1;
      this.get_eventwinninglist();
    },
    "searchData.type": function () {
      this.searchData.pageno = 1;
      this.get_eventwinninglist();
    },

    "searchData.pageno": function () {
      this.get_eventwinninglist();
    },
  },

  mixins: [myMixin],
};
</script>